<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tenants.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Tenant</h3>
                <b-button v-if="tenant" class="ml-auto" :href="`https://${tenant.id}.admin${$store.state.domain}`" target="_blank" variant="primary" :disabled="tenant.status !== 'ENABLED'">Admin</b-button>
            </b-card-header>
            <b-card-body v-if="tenant">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="tenant.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label." description="Please note this label is visible to our administrators.">
                            <b-form-input v-model="tenant.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="tenant.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="$store.state.subscriptions?.items">
                        <b-form-group label="Subscription" label-align-sm="right" label-cols-sm="3" description="Please note that changing the subscription will only take effect by start of the next month. Hence usage during the current month will remain to be counted against the current subscription.">
                            <b-form-select v-model="tenant.subscription" :options="$store.state.subscriptions.items" value-field="id" text-field="id" :disabled="!canEdit()"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="tenant?.metrics">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'metrics')">
                        <b-img src="/img/icons/metrics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Metrics</h5>
                    </b-card-header>
                    <b-collapse id="metrics" visible>
                        <b-card-body>
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="MAA" label-align-sm="right" label-cols-sm="3" description="These are the Monthly Active Accounts (MAA) for this tenant within the current month.">
                                        <b-form-input v-model="tenant.metrics.maa" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="MAC" label-align-sm="right" label-cols-sm="3" description="These are the Monthly API Calls (MAC) for this tenant within the current month.">
                                        <b-form-input v-model="tenant.metrics.mac" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer v-if="tenant.metrics.updated_at" class="text-muted bg-light">
                            <small>Last updated at {{ tenant.metrics.updated_at.toLocaleString() }}</small>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="tenant?.config">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                        <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                    </b-card-header>
                    <b-collapse id="configuration" visible>
                        <b-card-body v-if="tenant.config.interfaces">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'interfaces')">
                                    <b-img src="/img/icons/interface.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Interfaces</h5>
                                </b-card-header>
                                <b-collapse id="interfaces" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the primary color used for the Account and Admin UI.">
                                                    <template #label>
                                                        Color<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                                    </template>
                                                    <b-form-input v-model="tenant.config.interfaces.color" type="color" :disabled="!canEdit()"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Login</h5>
                                                        <b-button class="ml-auto" size="sm" variant="outline-danger" v-on:click="tenant.config.hlp = { background_color: '#FDFDFD', background_text_color: '#5E5669', background_link_color: '#3C78D8', widget_color: '#FFFFFF', widget_text_color: '#5E5669', widget_link_color: '#3C78D8', logo: `https://login${$store.state.domain}/img/logo-blue.png` }" :disabled="!canEdit()">Reset</b-button>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Background Color" label-align-sm="right" label-cols-sm="3">
                                                                    <b-form-input v-model="tenant.config.hlp.background_color" type="color" :disabled="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Background Text Color" label-align-sm="right" label-cols-sm="3">
                                                                    <b-form-input v-model="tenant.config.hlp.background_text_color" type="color" :disabled="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Background Link Color" label-align-sm="right" label-cols-sm="3">
                                                                    <b-form-input v-model="tenant.config.hlp.background_link_color" type="color" :disabled="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Widget Color" label-align-sm="right" label-cols-sm="3">
                                                                    <b-form-input v-model="tenant.config.hlp.widget_color" type="color" :disabled="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Widget Text Color" label-align-sm="right" label-cols-sm="3">
                                                                    <b-form-input v-model="tenant.config.hlp.widget_text_color" type="color" :disabled="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Widget Link Color" label-align-sm="right" label-cols-sm="3">
                                                                    <b-form-input v-model="tenant.config.hlp.widget_link_color" type="color" :disabled="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Current Logo" label-align-sm="right" label-cols-sm="3">
                                                                    <b-input-group>
                                                                        <b-form-input v-model="tenant.config.hlp.logo" readonly></b-form-input>
                                                                        <b-input-group-append>
                                                                            <b-button :href="tenant.config.hlp.logo" target="_blank" variant="outline-primary">Open</b-button>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="New Logo" label-align-sm="right" label-cols-sm="3" description="This is the new logo. Note we only accept JPEG and PNG formats and the logo can be maximum 440x440 pixels.">
                                                                    <b-input-group>
                                                                        <b-form-file v-model="tenant.config.hlp.new_logo" accept=".jpg, .jpeg, .png" :disabled="!canEdit()"></b-form-file>
                                                                        <b-input-group-append>
                                                                            <b-button v-on:click="delete tenant.config.hlp.new_logo" variant="outline-danger" :disabled="!tenant.config.hlp.new_logo">Clear</b-button>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Account</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the client ID for the Account UI.">
                                                                    <b-form-input v-model="tenant.config.interfaces.account.client" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/tenant.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Admin</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the client ID for the Admin UI.">
                                                                    <b-form-input v-model="tenant.config.interfaces.admin.client" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="tenant.config.tokens">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'tokens')">
                                    <b-img src="/img/icons/token.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Tokens</h5>
                                </b-card-header>
                                <b-collapse id="tokens" visible>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Login</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a login token is valid, i.e. how long users can stay logged in." :state="validField('config.tokens.login.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 1 year.">
                                                                    <b-form-input v-model="tenant.config.tokens.login.exp" :state="validField('config.tokens.login.exp')" :readonly="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                    <b-form-select v-model="tenant.config.tokens.login.use" :options="usages" disabled></b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/account-pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Signup</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a signup token is valid, i.e. how long users have to sign up." :state="validField('config.tokens.signup.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 1 hour.">
                                                                    <b-form-input v-model="tenant.config.tokens.signup.exp" :state="validField('config.tokens.signup.exp')" :readonly="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                    <b-form-select v-model="tenant.config.tokens.signup.use" :options="usages" disabled></b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/consent.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Consent</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a consent token is valid, i.e. how fast the login process must progress after consent.">
                                                                    <b-form-input v-model="tenant.config.tokens.consent.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                    <b-form-select v-model="tenant.config.tokens.consent.use" :options="usages" disabled></b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="tenant.config.accounts">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'accounts')">
                                    <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Accounts</h5>
                                </b-card-header>
                                <b-collapse id="accounts" visible>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Pending</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending account is deleted." :state="validField('config.accounts.pending.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 30 days.">
                                                                    <b-form-input v-model="tenant.config.accounts.pending.exp" :state="validField('config.accounts.pending.exp')" :readonly="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Enabled</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before an inactive account is deleted." :state="validField('config.accounts.enabled.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 400 days.">
                                                                    <b-form-input v-model="tenant.config.accounts.enabled.exp" :state="validField('config.accounts.enabled.exp')" :readonly="!canEdit()"></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="tenant.config.extensions">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'extensions')">
                                    <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Extensions</h5>
                                </b-card-header>
                                <b-collapse id="extensions" visible>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Pending</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending extension is deleted.">
                                                                    <b-form-input v-model="tenant.config.extensions.pending.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Enabled</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before an inactive extension is deleted">
                                                                    <b-form-input v-model="tenant.config.extensions.enabled.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Maximum" label-align-sm="right" label-cols-sm="3" description="This is the maximum amount of extensions.">
                                                                    <b-form-input v-model="tenant.config.extensions.enabled.max" type="number" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="tenant">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="tenant.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="tenant.created_by" readonly></b-form-input>
                            <b-badge v-if="tenant.created_by === $store.state.account_id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="tenant.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="tenant.updated_by" readonly></b-form-input>
                            <b-badge v-if="tenant.updated_by === $store.state.account_id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="tenant.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="success" v-on:click="saveTenant()" :disabled="!canEdit() || !validTenant()">Save</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="getTenant()">Refresh</b-button>
                <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-tenant', tenant)" :disabled="!canEdit()">Delete</b-button>
            </b-card-body>
            <b-card-footer v-if="tenant?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ tenant.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const USAGES = [
    { value: 0, text: 'Unlimited' },
    { value: 1, text: 'One-Time' }
];
const TIME_REGEX = new RegExp(/^(\d+|\d+\.\d+) ?(seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)$/i);
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = DAY * 365.25;

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Tenant',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // TENANT
            tenant: undefined,
            // STATUSES
            statuses: STATUSES,
            // USAGES
            usages: USAGES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.tenant) {
            this.getTenant();
        }
        if (!this.$store.state.subscriptions || !this.$store.state.subscriptions.items.length) {
            this.$emit('load', 'subscriptions');
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * TENANT
         */
        async getTenant() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getTenant($id: ID!) {
                                getTenant(id: $id) {
                                    id
                                    label
                                    status
                                    subscription
                                    metrics {
                                        maa
                                        mac
                                        updated_at
                                    }
                                    config {
                                        tokens {
                                            login {
                                                exp
                                                use
                                            }
                                            signup {
                                                exp
                                                use
                                            }
                                            consent {
                                                exp
                                                use
                                            }
                                        }
                                        accounts {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                            }
                                        }
                                        extensions {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                                max
                                            }
                                        }
                                        interfaces {
                                            color
                                            account {
                                                client
                                            }
                                            admin {
                                                client
                                            }
                                        }
                                        hlp {
                                            background_color
                                            background_text_color
                                            background_link_color
                                            widget_color
                                            widget_text_color
                                            widget_link_color
                                            logo
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.tenant = (await response.json()).data.getTenant;
                    // SET DATES
                    this.tenant.metrics.updated_at = this.tenant.metrics.updated_at ? new Date(this.tenant.metrics.updated_at) : undefined;
                    this.tenant.created_at = this.tenant.created_at ? new Date(this.tenant.created_at) : undefined;
                    this.tenant.updated_at = this.tenant.updated_at ? new Date(this.tenant.updated_at) : undefined;
                    this.tenant.expires_at = this.tenant.expires_at ? new Date(this.tenant.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.tenant.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tenant.', 'Tenant', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tenant.', 'Tenant', 'danger');
            }
            this.loading = undefined;
        },

        async saveTenant() {
            this.loading = 'Saving';
            try {

                // PROCESS LOGO
                if (this.tenant.config.hlp.new_logo) {
                    const reader = new FileReader();
                    reader.onerror = () => {
                        this.$emit('alert', 'Failed to read file.', 'Tenant', 'danger', 5000);
                        this.loading = undefined;
                    };
                    reader.onload = async () => {
                        this.tenant.config.hlp.logo = reader.result;
                        delete this.tenant.config.hlp.new_logo;
                        await this.saveTenant();
                    };
                    reader.readAsDataURL(this.tenant.config.hlp.new_logo);
                    return; // KEEP LOADING
                }

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateTenant($input: UpdateTenantInput!) {
                                updateTenant(input: $input) {
                                    id
                                    label
                                    status
                                    subscription
                                    metrics {
                                        maa
                                        mac
                                        updated_at
                                    }
                                    config {
                                        tokens {
                                            login {
                                                exp
                                                use
                                            }
                                            signup {
                                                exp
                                                use
                                            }
                                            consent {
                                                exp
                                                use
                                            }
                                        }
                                        accounts {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                            }
                                        }
                                        extensions {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                                max
                                            }
                                        }
                                        interfaces {
                                            color
                                            account {
                                                client
                                            }
                                            admin {
                                                client
                                            }
                                        }
                                        hlp {
                                            background_color
                                            background_text_color
                                            background_link_color
                                            widget_color
                                            widget_text_color
                                            widget_link_color
                                            logo
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.tenant.id}",
                                "label": "${this.tenant.label}",
                                "status": "${this.tenant.status}",
                                "subscription": "${this.tenant.subscription}",
                                "config": {
                                    "interfaces": {
                                        "color": "${this.tenant.config.interfaces.color}"
                                    },
                                    "tokens": {
                                        "login": {
                                            "exp": "${this.tenant.config.tokens.login.exp}"
                                        },
                                        "signup": {
                                            "exp": "${this.tenant.config.tokens.signup.exp}"
                                        }
                                    },
                                    "accounts": {
                                        "pending": {
                                            "exp": "${this.tenant.config.accounts.pending.exp}"
                                        },
                                        "enabled": {
                                            "exp": "${this.tenant.config.accounts.enabled.exp}"
                                        }
                                    },
                                    "hlp": {
                                        "background_color": "${this.tenant.config.hlp.background_color}",
                                        "background_text_color": "${this.tenant.config.hlp.background_text_color}",
                                        "background_link_color": "${this.tenant.config.hlp.background_link_color}",
                                        "widget_color": "${this.tenant.config.hlp.widget_color}",
                                        "widget_text_color": "${this.tenant.config.hlp.widget_text_color}",
                                        "widget_link_color": "${this.tenant.config.hlp.widget_link_color}",
                                        "logo": "${this.tenant.config.hlp.logo}"
                                    }
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const JSON = (await response.json());
                    // SUCCESS
                    if (JSON.data.updateTenant) {
                        this.tenant = JSON.data.updateTenant;
                        // SET DATES
                        this.tenant.metrics.updated_at = this.tenant.metrics.updated_at ? new Date(this.tenant.metrics.updated_at) : undefined;
                        this.tenant.created_at = this.tenant.created_at ? new Date(this.tenant.created_at) : undefined;
                        this.tenant.updated_at = this.tenant.updated_at ? new Date(this.tenant.updated_at) : undefined;
                        this.tenant.expires_at = this.tenant.expires_at ? new Date(this.tenant.expires_at) : undefined;
                        // ADD REFRESH DATE
                        this.tenant.refreshed_at = new Date();
                        this.$emit('alert', 'Your tenant has been updated', 'Tenant', 'success', 5000);
                        this.$emit('load', 'tenants');
                    // ERROR
                    } else {
                        switch (JSON.errors[0].message) {
                            case 'INVALID_PARAMETER':
                                this.$emit('alert', 'Failed to save tenant because you\'ve provided an invalid parameter. Please consult \'Events\' for more details.', 'Tenant', 'danger');
                                break;
                            default:
                                this.$emit('alert', 'Failed to save tenant.', 'Tenant', 'danger');
                        }
                        // RELOAD IF NEW LOGO
                        if (!this.tenant.config.hlp.logo.startsWith('https://')) {
                            await this.getTenant();
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save tenant.', 'Tenant', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save tenant.', 'Tenant', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.tenant?.status);
        },
        
        validTenant() {
            if (!this.validField('label')) return false;
            if (!this.validField('config.tokens.login.exp')) return false;
            if (!this.validField('config.tokens.signup.exp')) return false;
            if (!this.validField('config.accounts.pending.exp')) return false;
            if (!this.validField('config.accounts.enabled.exp')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.tenant.label;
                case 'config.tokens.login.exp':
                    if (!TIME_REGEX.test(this.tenant.config.tokens.login.exp)) return false;
                    return this.convert(this.tenant.config.tokens.login.exp) <= this.convert('1y');
                case 'config.tokens.signup.exp':
                    if (!TIME_REGEX.test(this.tenant.config.tokens.signup.exp)) return false;
                    return this.convert(this.tenant.config.tokens.signup.exp) <= this.convert('1h');
                case 'config.accounts.pending.exp':
                    if (!TIME_REGEX.test(this.tenant.config.accounts.pending.exp)) return false;
                    return this.convert(this.tenant.config.accounts.pending.exp) <= this.convert('30d');
                case 'config.accounts.enabled.exp':
                    if (!TIME_REGEX.test(this.tenant.config.accounts.enabled.exp)) return false;
                    return this.convert(this.tenant.config.accounts.enabled.exp) <= this.convert('400d');
                default:
                    return false;
            }
        },

        convert(time) {
            const matched = TIME_REGEX.exec(time);
            const value = parseFloat(matched[1]);
            const unit = matched[2].toLowerCase();
            switch (unit) {
                case 'sec':
                case 'secs':
                case 'second':
                case 'seconds':
                case 's':
                    return Math.round(value);
                case 'minute':
                case 'minutes':
                case 'min':
                case 'mins':
                case 'm':
                    return Math.round(value * MINUTE);
                case 'hour':
                case 'hours':
                case 'hr':
                case 'hrs':
                case 'h':
                    return Math.round(value * HOUR);
                case 'day':
                case 'days':
                case 'd':
                    return Math.round(value * DAY);
                case 'week':
                case 'weeks':
                case 'w':
                    return Math.round(value * WEEK);
                default:
                    return Math.round(value * YEAR);
            }
        }
    }
}
</script>